import {Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {Quizlet} from '@/models/Quizlet'
import {quizCardsModule} from '@/store'

@Module({
  namespaced: true,
  name: 'quizCards'
})
export class QuizCardsModule extends VuexModule {
  private cards: Array<Quizlet> = [];
  private moduleName: string = "";

  get quizCards(): Array<Quizlet> {
    return this.cards
  }

  get size(): number {
    return this.cards.length
  }

  get name(): string {
    return this.moduleName;
  }

  getCardAt(pos: number): Quizlet{
    return this.cards[pos];
  }

  @Mutation
  setName(name: string) {
    this.moduleName = name;

    for (let i = 0; i < this.cards.length; i++){
      this.cards[i].name = this.moduleName;
    }
  }

  @Mutation
  addQuizlet(quizlet: Quizlet) {
    this.cards = [...this.cards, quizlet]
  }

  @Mutation
  addEmptyQuizCard() {
    this.cards = [...this.cards, {original: '', translation: '', trained: 0, see: false, finished: false, inLearnCards: false, name: this.moduleName}]
  }

  @Mutation
  removeQuizCardAtIndex(index: number) {
    this.cards.splice(index, 1)
  }

  @Mutation
  removeQuizletAtItem(item: Quizlet) {
    const index: number = this.cards.indexOf(item)
    this.cards.splice(index, 1)
  }

  @Mutation
  setCards(cards: Array<Quizlet>) {
    this.cards = cards
    this.moduleName = cards[0].name;
  }

  @Mutation
  clear() {
    this.cards = []
  }

  @Mutation
  setAllLearnTo(bool: boolean) {
    for (let i = 0; i < this.cards.length; i++) {
      this.cards[i].inLearnCards = bool;
      this.cards[i].see = bool;
    }
  }

  @Mutation
  setAllFinishedTo(bool: boolean) {
    for (let i = 0; i < this.cards.length; i++) {
      this.cards[i].finished = bool;
    }
  }

  get properties(): Array<number> {
    let notSee: number = 0;
    let see: number = 0;
    let learned: number = 0;
    let back: Array<number>;

    for (let i = 0; i < this.cards.length; i++){
      if (!this.cards[i].finished && !this.cards[i].inLearnCards) notSee++;
      else if (!this.cards[i].finished && this.cards[i].inLearnCards) see++;
      else if (this.cards[i].finished && this.cards[i].inLearnCards) learned++;
    }

    back = [notSee, see, learned];

    return back;
  }
}
