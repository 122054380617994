



















































import { Component, Vue } from 'vue-property-decorator'
import {QuizCardsModule, quizCardsModule} from '@/store'
import { env } from '@/environment'
import {Quizlet} from "@/models/Quizlet";

@Component
export default class Load extends Vue {
  public selectedFile: File | null = null;
  uploadDialog: boolean = false;
  allCards = this.onlineCards;
  allCardsName = this.cardsNames;
  selectedInput: string = "";

  onLoad() {
    const learnset = this.allCards[(this.allCardsName.indexOf(this.selectedInput))];
    quizCardsModule.setCards(learnset);
    this.$router.push('/learn')
  }

  get cardsNames(): Array<string>{
    let names: Array<string> = new Array<string>();
    for (let i = 0; i < this.allCards.length; i++){
      names.push(this.allCards[i][0].name)
    }
    return names;
  }

  get onlineCards(): Array<Array<Quizlet>>{
    const allCards = JSON.parse(<string>localStorage.getItem('cards'));
    let allDCards: Array<Array<Quizlet>> = [];

    for (let i = 0; i < allCards.length; i++){
      allDCards.push(JSON.parse(allCards[i]));
    }

    return allDCards;
  }

  onUpload () {
    if (this.selectedFile === null || this.selectedFile === undefined) return

    env.file = this.selectedFile

    const reader = new FileReader()

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const data = JSON.parse(reader.result)
        quizCardsModule.setCards(data)
        this.$router.push('/learn')
      }
    }

    reader.readAsText(this.selectedFile)
  }
}
