






































































import { Component, Vue } from 'vue-property-decorator'
import { quizCardsModule } from '@/store'
import { Quizlet } from '@/models/Quizlet'
import { env } from '@/environment'

@Component
export default class Learn extends Vue {
  cardTitle = 'Test';
  answer = '';
  learnQuizlets: Array<Quizlet> = new Array<Quizlet>();
  card: Quizlet = { original: '', translation: '', trained: 0, see: false, finished: false, inLearnCards: false, name: ""};
  switch = false;
  finished = false;
  next = false;
  nAnswer = 'Right';
  rAnswer = 'Hello';
  fileName = '';
  sidebar: boolean = true;
  notSee: number = 0;
  see: number = 0;
  learned: number = 0;
  properties: Array<number> = [];

  initProperties(){
    this.properties = quizCardsModule.properties;
    this.notSee = this.properties[0];
    this.see = this.properties[1];
    this.learned = this.properties[2];
  }

  onEnter () {
    this.initProperties();
    if (this.next) {
      this.next = false
      this.showLearnCard()
    } else {
      this.nextCard()
    }
  }

  finish () {
    this.finished = false
    this.$router.push('/')
  }

  initLearnCards () {
    if (quizCardsModule.size > 7) {
      for (let i = 0; i < 7; i++) {
        const learnCard = this.randomCard(quizCardsModule.quizCards, false, false)
        if (learnCard !== null){
          learnCard.inLearnCards = true
          this.learnQuizlets.push(learnCard)
        }
        else console.debug("ERROR");
      }
    } else {
      for (let i = 0; i < quizCardsModule.size; i++) {
        const learnCard = this.randomCard(quizCardsModule.quizCards, false, false)
        if (learnCard !== null){
          learnCard.inLearnCards = true
          this.learnQuizlets.push(learnCard)
        }
        else console.debug("ERROR");
      }
    }
    this.showLearnCard()
  }

  showLearnCard () {
    if (!this.isFinished()) {
      this.answer = '';
      this.switch = Math.random() >= 0.5;
      const randomCard = this.randomCard(this.learnQuizlets, true, false);
      if (randomCard !== null) this.card = randomCard;
      if (randomCard !== null) quizCardsModule.quizCards[quizCardsModule.quizCards.indexOf(randomCard)].see = true;

      if (this.switch) {
        this.cardTitle = this.card.translation;
      } else {
        this.cardTitle = this.card.original;
      }
    }
  }

  nextCard () {
    console.debug(this.switch)

    if (this.switch) {
      if (this.controlCard(this.card.original, this.answer)) {
        this.nAnswer = 'Right'
        this.rAnswer = this.card.original
        this.card.trained++
      } else {
        this.nAnswer = 'Wrong'
        this.rAnswer = this.card.original
        if (this.card.trained > 0) {
          this.card.trained--
        }
      }
    } else {
      if (this.controlCard(this.card.translation, this.answer)) {
        this.nAnswer = 'Right'
        this.rAnswer = this.card.translation
        this.card.trained++
      } else {
        this.nAnswer = 'Wrong'
        this.rAnswer = this.card.translation
        if (this.card.trained > 0) {
          this.card.trained--
        }
      }
    }

    if (this.card.trained >= 6) {
      this.learnQuizlets[this.learnQuizlets.indexOf(this.card)].finished = true
      quizCardsModule.quizCards[quizCardsModule.quizCards.indexOf(this.card)].finished = true

      if (quizCardsModule.quizCards === this.learnQuizlets) {
        console.log('add Card')
        const randomCard = this.randomCard(quizCardsModule.quizCards, false, false);
        if (randomCard !== null) this.learnQuizlets.push();
      }

      console.debug('Finished')
    }

    if (this.isFinished()) {
      this.next = false
      this.finished = true
      return
    }

    this.next = true
  }

  randomCard (input: Array<Quizlet>, inCards: boolean, finished: boolean): Quizlet | null {
    let quizlet: Quizlet | null = null;
    let quizletWPC: Array<Quizlet> = [];

    for (let i = 0; i < input.length; i++){
      if (input[i].inLearnCards === inCards && input[i].finished === finished){
        quizletWPC.push(input[i]);
        console.debug("push");
      }
    }

    if (quizletWPC.length !== 0){
      quizlet = input[Math.floor(Math.random() * input.length)];
    }

    return quizlet;
  }

  isFinished (): boolean {
    let finishedCards = 0

    for (let i = 0; i < quizCardsModule.quizCards.length; i++) {
      if (quizCardsModule.quizCards[i].finished) {
        finishedCards++
      }
    }

    return finishedCards === quizCardsModule.quizCards.length
  }

  mounted () {
    if (quizCardsModule.size === 0) {
      this.$router.push('/')
    } else {
      if (env.file != null) {
        this.fileName = env.file.name;
      }
      this.initLearnCards();
      this.initProperties();
      env.saveText = "Save";
    }
  }

  controlCard(original: string, answer: string): boolean{
    let returnBool: boolean = false;

    returnBool = this.answerScwitch(answer, original, returnBool);

    original = this.extractBrackets(original);

    returnBool = this.answerScwitch(answer, original, returnBool);

    return returnBool;
  }

  extractBrackets(input: string): string{
    let original: string = input;
    let brackets: boolean = false;
    let bracketsPos: number = 0;
    for (let i = 0; i < original.length; i++){
      console.debug(i);
      if (original.charAt(i) === "("){
        console.debug("Brackets")
        brackets = true;
        bracketsPos = i;
      }
      if (original.charAt(i) === ")" && brackets){
        original = this.cutOutString(original, bracketsPos, i);
        console.debug(original);
      }
    }

    return original
  }

  answerScwitch(input1: string, input2: string, inputBool: boolean): boolean{
    const capitalize = (s: any) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
    const deCapitalize = (s: any) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toLowerCase() + s.slice(1);
    }
    let returnBool: boolean = inputBool;

    switch (input1){
      case input2:
        returnBool = true;
        break;
      case input2 + " ":
        returnBool = true;
        break;
      case capitalize(input2):
        returnBool = true;
        break;
      case deCapitalize(input2):
        returnBool = true;
        break;
      case capitalize(input2) + " ":
        returnBool = true;
        break;
      case deCapitalize(input2) + " ":
        returnBool = true;
        break;
      case " " + input2:
        returnBool = true;
        break;
      case " " + input2 + " ":
        returnBool = true;
        break;
      case " " + capitalize(input2):
        returnBool = true;
        break;
      case " " + deCapitalize(input2):
        returnBool = true;
        break;
      case " " + capitalize(input2) + " ":
        returnBool = true;
        break;
      case " " + deCapitalize(input2) + " ":
        returnBool = true;
        break;
    }

    return returnBool;
  }

  cutOutString(input: string, from: number, to: number): string{
    let backString: string = "";

    backString = backString + input.slice(0, from - 1);
    backString = backString + input.slice(to + 1, input.length - 1);

    return backString;
  }

}
