





























































































































import { Component, Vue } from 'vue-property-decorator'
import { Quizlet } from '@/models/Quizlet'
import { quizCardsModule } from '@/store'
import { saveAs } from 'file-saver'

@Component
export default class Create extends Vue {
  model = 0;
  color = '#00E676';
  background = '#ffffff';
  textFieldColor = '#2d2d2d';
  dialog = false;
  fileName = 'my_file';
  saveDialog: boolean = false;
  errorNoneFile: boolean = false;
  warningDialog: boolean = false;

  saveCardsOnline() {
    /*Sets the file Name*/
    if (this.fileName.endsWith('.gi')) this.fileName.slice(0, -3);
    quizCardsModule.setName(this.fileName);

    /*Saves all modules in cards*/
    let cards: Array<string> = new Array<string>();
    cards = JSON.parse(<string>localStorage.getItem('cards'))

    /*if cards == null create new array*/
    if (cards === null) cards = new Array<string>();

    /*clears the storage*/
    localStorage.clear()

    /*resets the learn status*/
    quizCardsModule.setAllLearnTo(false);

    /*adds the module to the cards*/
    cards.push(JSON.stringify(quizCardsModule.quizCards));

    /*saves cards in storage*/
    localStorage.setItem('cards', JSON.stringify(cards));

    /*closes dialog*/
    this.warningDialog = false;
    this.$router.push('/');
  }

  get quizlets (): Array<Quizlet> {
    return quizCardsModule.quizCards
  }

  newItem () {
    quizCardsModule.addEmptyQuizCard()
  }

  removeQuizCard (index: number) {
    quizCardsModule.removeQuizCardAtIndex(index)
  }

  mounted() {
    quizCardsModule.addEmptyQuizCard();
  }

  save() {
    if (quizCardsModule.quizCards.length > 0){
      this.saveDialog = true;
    }
    else {
      this.errorNoneFile = true;
    }
  }

  saveCards() {
    quizCardsModule.setAllLearnTo(false);
    const blob = new Blob([JSON.stringify(quizCardsModule.quizCards)], { type: 'application/json;charset=utf-8' });
    if (!this.fileName.endsWith('.gi')) this.fileName += '.gi';
    saveAs(blob, this.fileName);
    this.saveDialog = false;
    this.$router.push('/')
  }

}
