import { QuizCardsModule } from '@/store'

export interface Environment {
    appName: string;
    file: File | null;
    saveText: string;
}

export const env: Environment = {
  appName: 'GotIt',
  file: null,
  saveText: ""
}
