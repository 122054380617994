import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { initializeModules, QuizCardsModule } from '@/store/accessor'

Vue.use(Vuex)

const vuexOptions: StoreOptions<{}> = {
  plugins: [
    initializeModules
  ],
  modules: {
    quizCards: QuizCardsModule
  }
}

export const store = new Vuex.Store(vuexOptions)
export * from '@/store/accessor'
