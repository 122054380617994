

















import { Component, Vue } from 'vue-property-decorator'
import { env } from '@/environment'

@Component
export default class Home extends Vue {

}
