import {Store} from "vuex";
import {getModule} from "vuex-module-decorators";
import {QuizCardsModule} from "@/store/modules/QuizCardsModule";

let quizCardsModule: QuizCardsModule;

export const initializeModules = (store: Store<{}>) => {
  quizCardsModule = getModule(QuizCardsModule, store);

  console.log("Modules Initialized");
}

export {
  quizCardsModule, QuizCardsModule
}
