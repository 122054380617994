






























































































import {Component, Vue} from 'vue-property-decorator'
import {QuizCardsModule, quizCardsModule} from "@/store";
import {saveAs} from "file-saver";
import {Quizlet} from "@/models/Quizlet";

@Component
export default class App extends Vue {
  homeText: string = "Home";
  loadText: string = "Load";
  createText: string = "Create";
  saveText: string = "Save";
  errorNoneFile: boolean = false;
  fileName: string = "my_file";
  saveDialog: boolean = false;
  warningDialog: boolean = false;

  metaInfo() {
    return {
      title: "GotIt - Learn easy and fast",
      meta: [
        { name: 'description', content:  'Learn easy and fast your vocabulary online.'},
        { property: 'og:title', content: "GotIt - Learn easy and fast"},
        { property: 'og:site_name', content: 'GotIt'},
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  }

  home() {
    this.$router.push("/");
    quizCardsModule.clear();
  }

  load() {
    this.$router.push("/load");
    quizCardsModule.clear();
  }

  create() {
    this.$router.push("/create");
    quizCardsModule.clear();
  }

  save() {
    if (quizCardsModule.name !== ""){
      this.fileName = quizCardsModule.name;
    }

    if (quizCardsModule.quizCards.length > 0){
      this.saveDialog = true;
    }
    else {
      this.errorNoneFile = true;
    }
  }

  saveCards() {
    quizCardsModule.setAllLearnTo(false);
    const blob = new Blob([JSON.stringify(quizCardsModule.quizCards)], { type: 'application/json;charset=utf-8' });
    if (!this.fileName.endsWith('.gi')) this.fileName += '.gi';
    saveAs(blob, this.fileName);
    this.saveDialog = false;
    this.$router.push('/');
  }

  saveCardsOnline() {
    /*Sets the file Name*/
    if (this.fileName.endsWith('.gi')) this.fileName.slice(0, -3);
    quizCardsModule.setName(this.fileName);

    /*Saves all modules in cards*/
    let cards: Array<string> = new Array<string>();
    cards = JSON.parse(<string>localStorage.getItem('cards'))

    /*if cards == null create new array*/
    if (cards === null) cards = new Array<string>();

    /*clears the storage*/
    localStorage.clear()

    /*resets the learn status*/
    quizCardsModule.setAllLearnTo(false);

    /*adds the module to the cards*/
    cards.push(JSON.stringify(quizCardsModule.quizCards));

    /*saves cards in storage*/
    localStorage.setItem('cards', JSON.stringify(cards));

    /*closes dialog*/
    this.warningDialog = false;
    this.$router.push('/');
  }
}

